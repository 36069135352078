// noinspection NonAsciiCharacters

import React from 'react'

const smallEmoji = (name: string): string => `<img src="/${name}.png" alt="${name}" class="emoji"/>`

const emojiMap: { [key: string]: string } = {
    '💪🏻': smallEmoji('arm'),
    '🏢': smallEmoji('building'),
    '💡': smallEmoji('bulb'),
    '🚌': smallEmoji('bus'),
    '📈': smallEmoji('chart'),
    '🧀': smallEmoji('cheese'),
    '📋': smallEmoji('clipboard'),
    '👩🏼‍🎓': smallEmoji('grad'),
    '🖥': smallEmoji('imac'),
    '💻': smallEmoji('laptop'),
    '🖍': smallEmoji('marker'),
    '🤓': smallEmoji('nerd'),
    '🎉': smallEmoji('party'),
    '🚀': smallEmoji('rocket'),
    '🏃🏼‍♀️': smallEmoji('runner'),
    '🏃‍': smallEmoji('runner'),
    '☀️': smallEmoji('sun'),
    '😎': smallEmoji('sunshine'),
    '✅': smallEmoji('tick'),
    '🪄': smallEmoji('wand'),
    '👷🏼‍♀️': smallEmoji('workwoman'),
}

export const EmojiReplacer = (text: string): string => {
    const isApple = window.navigator.userAgent.includes('Mac OS X') // This covers Macs, iPhone and iPad
    if (isApple) return text

    let converted = text
    Object.keys(emojiMap).forEach(emoji => {
        converted = converted.replace(emoji, emojiMap[emoji])
    })
    return converted
}