import React, { ReactNode } from 'react'

import './layout.css'

type Props = {
    children: ReactNode[];
}

export const Layout: React.FC<Props> = ({ children }: Props) => (
  <div>
    <main>{children}</main>
    <footer>
      © {new Date().getFullYear()} Leanne Walsham
    </footer>
  </div>
)
